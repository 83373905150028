<template>
  <div class="pcdownload">
    <div class="download-info-row">
      <div class="download-title"><img src="@/assets/gb/downloadedfile@2x.png" alt="" /> Download
        started</div>
      <div class="download-memo">
        When the download completed, please click the apk file to install.
      </div>

      <div class="download-rating-coantiner">
        <div class="rating-title"><img src="@/assets/like.png" alt="" /> Please rate us</div>

        <div class="rating-control-row">
          <div class="star-item" :class="{ active: i <= ratingStar }" @click="rate(i)"
            v-for="i in 5" :key="i"></div>
        </div>
      </div>

      <div class="collect-me-container" v-if="showCollect" @click="showCollect = false">
        <div class="collect-me-row">
          <img src="@/assets/close-icon.png" class="collect-close" @click="showCollect = false"
            alt="" />
          <h4>Thank you for your feedback</h4>
          <img class="collect-pic" src="@/assets/gb/collect.webp" alt="" />
          <p>It is highly recommended to bookmark our website or join our official channel to timely
            obtain the latest version updates to avoid product expiration</p>
        </div>
      </div>

      <div class="download-gif">
        <img src="@/assets/gb/donwl.gif" alt="" />
      </div>
    </div>

    <div class="ad-container">
      <div class="tuimod-text">
        The best tools make WhatsApp more useful & poewrful.
      </div>

      <div class="big-tuimod has-exp" v-if="!isSouthAfrica" exp-data="vml">
        <div class="big-tuimod-info-row">
          <div class="big-tuimod-info">
            <div class="big-tuimod-title">
              Status Downloader Pro
            </div>
            <div class="big-tuimod-des">
              More Videos & Status YouTube, Facebook, Tiktok, Instagram
            </div>
          </div>
          <div class="download-btn" @click="downloadAPK(vmlapk, 'vml')">
            DOWNLOAD
          </div>
        </div>
      </div>

      <div class="big-tuimod has-exp za" exp-data="za" v-if="isSouthAfrica" @click="handleClick">
        <div class="big-tuimod-info-row">
          <div class="big-tuimod-info">
            <div class="big-tuimod-title">
              Twerk
            </div>
            <div class="big-tuimod-des">
              The combination of parkour games and twerking can bring you unlimited fun!
            </div>
          </div>
          <div class="download-btn">
            DOWNLOAD
          </div>
        </div>
      </div>

      <div class="tuimod-load-list">
        <div class="tuimod-load-list-item has-exp" :exp-data="data.name"
          v-for="(data, i) in moreItem" :key="i">
          <div class="ad-img"
            :style="{ backgroundImage: `url(${data.icon})`, backgroundSize: '100%' }"></div>
          <div class="tuimod-load-list-row">
            <div class="tuimod-load-list-info">
              <div class="tuimod-load-list-title">
                {{ data.title }}
              </div>
              <div class="tuimod-load-list-des">
                {{ data.advertise }}
              </div>
            </div>
            <div class="tuimod-load-list-download-btn" @click="downloadAPK(data.apk, data.name)">
              DOWNLOAD
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import "@/css/gb/pcdownload.scss";
import "@/css/gb/mdownload.scss";
export default {
  name: "download",
  data () {
    return {
      pageName: "download",
      isSouthAfrica: false, // 是否在南非
      baseUrl:
        "http://api.rethinkad.com/moonlight/v4?offer_id=3703334&affiliate_id=10028",
      vmlapk: "https://cdn.gbwhatsapp.download/app/android/apk/vidtmate-3.0074.apk",
      moreItem: [
        {
          name: "Netflix",
          title: "Netflix Unlock Mod",
          icon: require("@/assets/gb/Netflix.webp"),
          advertise: "Download Latest Movies & TV Series",
          apk: "https://www.cdns.world/vml/apk/launch/Netflix_Free_1.6.4_gb.apk",
        },
      ],
      ratingStar: null,
      showCollect: false,
      gbIOS: false,

      appLogo: null,
      apk: null,

      from: null,
      filename: null,
      domain: null,
      appName: null,
    };
  },
  beforeMount () {
    var apps = this.$global.GetQueryString("apps") || "gb";
    const from = this.$global.GetQueryString("from");
    const filename = this.$global.GetQueryString("filename");
    const isOffical = this.$global.GetQueryString("isOffical");
    const domain = this.$global.GetQueryString("domain");
    const appName = this.$global.GetQueryString("appName");

    console.log(apps, from, filename, isOffical, domain, appName);

    [this.from, this.filename, this.domain, this.appName] = [
      from,
      filename,
      domain,
      appName,
    ];


    console.log(appName);
    if (apps == 'home') apps = 'gb'


    var isIOS;
    if (typeof navigator !== "undefined") {
      // 在浏览器环境中执行的代码
      isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) &&
        !window.MSStream;
    } else {
      isIOS = false;
    }

    if (isIOS && this.appName == "GBWhatsApp") {
      this.gbIOS = true;
    }

    if (from && filename) {
      console.log(`ready to get ${from} apk`);
      this.getAPK();
    } else if (isOffical != 0) {
      console.log("ready to get official apk");
      this.getOfficalApk(2);
    } else {
      console.log("ready to get default apk");
      this.getOfficalApk();
    }

    this.appName = this.$store.state[apps].name;
    this.appLogo = this.$store.state[apps].logo;
  },
  mounted () {
    this.checkLocale();

    let script = document.querySelector("#script");
    if (script) {
      return;
    }

    let recaptchaScript = document.createElement("script");
    recaptchaScript.async = true;
    recaptchaScript.id = "script";
    recaptchaScript.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3240446016388496"
    );
    recaptchaScript.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    // 检查用户的语言偏好是否符合南非的条件
    checkLocale () {
      const userLocale = navigator.language || navigator.userLanguage;
      const country =
        document.getElementsByTagName("meta")["user_country"].content;
      console.log(country);
      if (country) {
        this.isSouthAfrica = country.includes("ZA");
      } else {
        this.isSouthAfrica = userLocale.includes("za"); // 假设南非的语言代码包含 'za'
      }
      console.log(this.isSouthAfrica);
    },
    // 生成随机UUID
    generateUUID () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] &
            (15 >> (c / 4)))
        ).toString(16)
      );
    },
    // 点击事件处理函数
    handleClick () {
      const ip = document.getElementsByTagName("meta")["user_ip"].content;
      const country =
        document.getElementsByTagName("meta")["user_country"].content;
      const params = new URLSearchParams({
        click_id: this.generateUUID(),
        ua: navigator.userAgent,
        os_version: navigator.appVersion,
        os_platform: navigator.platform,
        ip: ip,
        country_code: country ? country : "ZA",
        // offer_name, oaid, mac, ip, imei, idfa, ext, device_model, creative_name, creative_id, country_code, android_id
        // 上述字段根据实际情况填充或通过其他方法获取
      }).toString();

      const url = `${this.baseUrl}&${params}`;
      console.log(url);
      window.location.href = url; // 跳转到指定的链接
    },
    getAPK () {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$store.state.activeAPK = res.data.apk;
          } else {
            this.$store.state.activeAPK =
              process.env.VUE_APP_GBLINK;
          }

          setTimeout(() => {
            this.$logEvent("show_downloadstart");
            this.$logEvent(`click_${this.appName}_download`);
            if (this.gbIOS) {
              this.$logEvent("downloadstart_ios");
              window.location.href =
                "itms-apps://itunes.apple.com/app/6445970358";
              return;
            }
            this.$global.download(this.$store.state.activeAPK);
          }, 500);
        });
    },
    getOfficalApk (set = 1) {
      this.$server
        .getOfficalApk({ domain: this.domain, appName: this.appName })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            console.log("apk getted");
            console.log(res.data.promotion.apk);
            if (set == 1) {
              //取自随机的
              this.$store.state.activeAPK =
                res.data.promotion.apk;
            }
            if (set == 2) {
              this.$store.state.activeAPK = res.data.apk;
            }
          } else {
            this.$store.state.activeAPK =
              process.env.VUE_APP_GBLINK;
          }

          setTimeout(() => {
            this.$logEvent("show_downloadstart");
            this.$logEvent(`click_${this.appName}_download`);
            if (this.gbIOS) {
              this.$logEvent("downloadstart_ios");
              window.location.href =
                "itms-apps://itunes.apple.com/app/6445970358";
              return;
            }
            this.$global.download(this.$store.state.activeAPK);
          }, 500);
        });
    },
    rate (i) {
      if (this.$store.state.voteScore) {
        return;
      }
      console.log(i);
      this.ratingStar = i;
      this.rateScore(i);
    },
    rateScore (score) {
      this.$server.saveappScore({ as: "gbw", s: score }).then((res) => {
        console.log(res);
        this.$store.commit("UPDATE_VOTING", score);
        console.log(this.$store.state.gb);
        this.showCollect = true;
        setTimeout(() => {
          this.showCollect = false;
        }, 8000);
      });
    },
    downloadAPK (apk, name) {
      this.$global.download(apk);
      this.$logEvent(`click_downloadstart_${name}`, {
        platform: "mobile",
      });
    },
  },
};
</script>
